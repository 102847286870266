<template>
    <v-col cols="12">
        <v-card>
            <v-card-title>Parametrização de orçamento</v-card-title>
            <v-card-text>
                <template>
                    <input-v
                        v-for="(field, index) of cols"
                        class="pl-4"
                        :colSize="field.colSize"
                        :key="index"
                        :label="field"
                        :opts="opts"
                        :valid.sync="field.valid"
                        v-model="parametrizacao[field.key]"
                    >
                    </input-v>
                </template>
                <v-card v-if="parcelas.length">
                    <v-card-title>Parcelas</v-card-title>
                    <v-card-text>
                        <input-v
                            v-for="(field, index) of colsParcelas"
                            class="pl-4"
                            :colSize="field.colSize"
                            :key="index"
                            :label="field"
                            :opts="opts"
                            :valid.sync="field.valid"
                            v-model="parcelas[field.key]"
                        >
                        </input-v>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-btn>Cancelar</v-btn>
                <v-btn>Salvar</v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</template>

<script>

export default {
    // props: {},
    name: 'Parametrização de orçamento',
    components: {
        'input-v': () => import("@/components/input-v.vue"),
    },
    // mixins: [],
    // directives: {},
    computed: {
        cols() {
            return [
                {
                    key: 'qtd_parcelas',
                    name: 'Quantidade parcelas',
                    type: this.$fieldTypes.TEXT,
                    colSize: 4,
                },
                {
                    key: 'percentual_contrapartida',
                    name: 'Contrapartida (%)',
                    type: this.$fieldTypes.TEXT,
                    colSize: 4,
                },
                {
                    key: 'percentual_financiado',
                    name: 'Financiado (%)',
                    type: this.$fieldTypes.TEXT,
                    colSize: 4,
                },
                {
                    key: 'percentual_comprovacao',
                    name: 'Comprovação (%)',
                    type: this.$fieldTypes.TEXT,
                    colSize: 4,
                },
                {
                    key: 'total',
                    name: 'Valor total do projeto',
                    type: this.$fieldTypes.TEXT,
                    colSize: 4,
                },
            ]
        },
        colsParcelas() {
            return [
                {
                    key: 'nro_parcela',
                    name: 'Nº da parcela',
                    type: this.$fieldTypes.TEXT,
                    colSize: 4,
                },
                {
                    key: 'data_inicio',
                    name: 'Data inicio',
                    type: this.$fieldTypes.DATE,
                    colSize: 4,
                },
                {
                    key: 'data_fim',
                    name: 'Data fim',
                    type: this.$fieldTypes.DATE,
                    colSize: 4,
                },
                {
                    key: 'valor_contrapartida',
                    name: 'Valor total contrapartida',
                    type: this.$fieldTypes.TEXT,
                    colSize: 4,
                },
                {
                    key: 'total_financiado',
                    name: 'Valor total financiado',
                    type: this.$fieldTypes.TEXT,
                    colSize: 4,
                },
            ]
        }
    },
    //filters: {},
    data() {
        return {
            parametrizacao: {},
            parcelas: []
        }
    },
    // created() {},
    // mounted() {},
    // updated() {},
    // destroyed() {},
    methods: {

    },
    // watch: {},
}
</script>

<style>
</style>